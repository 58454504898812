@import "icons";
@import "../node_modules/react-day-picker/lib/style";
@import "colors";
@import "settings";
@import "breakpoint_old";
@import "breakpoints";

.wethod {
  &-body {
    &--with-app-banner {
      // Adapt content when app banner is visible to avoid double scrolling
      .wethod-section {
        min-height: calc(100vh - 126px);
      }
    }
  }

  &-unstyled-button {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    box-shadow: none;
    line-height: inherit;
    color: inherit;
    text-align: inherit;
    font: inherit;
  }

  &-no-scroll {
    overflow: hidden;
  }

  &-highlighter {
    &--search-keyword {
      input {
        color: $garden-green;
        font-weight: 700;
      }
    }
  }

  &-export-button {
    line-height: 33px;
    display: inline-flex;
    font-size: 14px;
    border: none;
    color: $bright-sky-blue;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    &__icon {
      order: 1;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &-radio {
    margin: 4px 0;
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    width: 100%;

    input {
      display: none;
    }

    &__label {
      margin: 0 10px;
      line-height: 18px;
      min-width: 0;
      width: 100%;
    }

    &.disabled {
      cursor: default;
    }

    &__icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    &-collapsible {
      border-bottom: 1px solid $concrete-gray;
      padding: 5px 16px;
      word-break: break-all;
      display: flex;
      justify-content: space-between;
      align-items: self-start;

      .wethod-accordion {
        flex-grow: 1;

        &__button {
          align-items: start;
        }
      }

      .wethod-radio {
        display: flex;
        align-items: start;
        width: auto;

        &__icon {
          flex-shrink: 0;
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: start;
        min-height: 57px;
        padding: 5px 0;
      }

      &__body {
        padding-left: 32px;
      }

      &__label {
        display: inline-flex;
        justify-content: space-evenly;
        flex-direction: column;
      }

      &__title {
        font-weight: $semiBold;
        font-size: 14px;
        display: block;
      }

      &__subtitle {
        font-size: 12px;
      }

      &__description {
        font-size: 12px;
        color: $dusty-gray;
        display: block;
        margin-top: 4px;
      }

      .wethod-icon {
        transition: background-color 0s, transform .2s;
        border-radius: 50%;

        &-dropdown {
          flex-shrink: 0;
        }

        &:hover {
          background-color: #ECECEC;
        }
      }
    }
  }

  &-table-light {

    &__row {
      border-bottom: 1px solid $concrete-gray;
      padding: 6px 0;
      display: block;

      &--header {
        font-weight: $semiBold;
      }

      &--no-border {
        border-bottom: none;
      }
    }

    &__cell {
      width: 25%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  &-app-header {
    &__fixed {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 900;
    }
  }

  &-logo {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;

    &--monogram {
      background-image: url("../img/logo-monogram.svg");
    }
  }

  &-menu {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 10000;
    font-size: 14px;

    &:hover {
      .wethod-input {
        background: initial !important;
      }
    }

    &__list {
      background: $white;
      border: 1px solid $concrete-gray;
      box-shadow: 0 2px 4px 0 #CCCBCC;
      position: absolute;
      display: inline-block;
      list-style-type: none;
      border-radius: 12px;

      ul {
        max-height: 50vh;
        overflow-y: auto;
      }
    }

    &__item {
      padding: 16px;
      font-size: 14px;
      transition: 0.2s;
      line-height: 18px;
      font-weight: $normal;

      &:not(.wethod-menu__item--disabled) {
        cursor: pointer;
      }

      &:not(.wethod-menu__item--disabled):hover {
        background-color: #ECECEC;
      }

      &--colorful {
        min-width: 200px;
        height: 50px;
        font-size: 14px;
        text-align: center;
        margin: 2px 4px;
        cursor: pointer;
        position: relative;
        transition: 0.3s;
        border-radius: 12px;

        &:hover {
          opacity: 0.9;
          transform: scale(0.9);
        }
      }

      &--hidden {
        display: none;
        visibility: hidden;
      }

      &--disabled {
        opacity: 0.4 !important;
      }

      &--link {
        padding: 0;

        a {
          display: block;
          padding: 16px;
          text-align: left;
          white-space: nowrap;
          color: $black;
        }
      }
    }

    &__list > &__item {
      &:first-child {
        border-start-start-radius: inherit;
        border-start-end-radius: inherit;
      }
      &:last-child {
        border-end-start-radius: inherit;
        border-end-end-radius: inherit;
      }
    }

    &__footer {
      padding: 8px 16px;
    }
  }

  &-open-sidebar-button {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    cursor: pointer;
    background: $white;
    transition: .4s;
    height: 32px;

    &:hover {
      background-color: $bright-sky-blue;
    }

    &__icon {
      display: inline-block;
      //the icon should be white on hover, since the background gets blue
      &:hover {
        background-position-x: - 64px;
      }
    }
  }

  &-table {
    table-layout: fixed; // The main benefit of table-layout: fixed; is that the table renders much faster
    font-size: 14px;

    &__search {
      border: 1px solid $concrete-gray;
      border-bottom: none;
      height: 52px;
      line-height: 52px;
      width: 100%;
      transition: .2s;
      padding-left: 8px;
      padding-right: 16px;
      border-radius: 12px 12px 0 0;
      background: $white;
      display: flex;
      align-items: center;

      &-copyable-area {
        position: fixed;
        left: -9999px;
      }

      &-copy-button {
        color: $bright-sky-blue;
        border: none;
        cursor: pointer;
        outline: none;
        background-color: $white;
        font-weight: 600;
        margin-right: 4px;
      }

      input[type="text"] {
        flex-grow: 2;
        padding: 7px;
        margin: 0;
        border: none;
        outline: none;
        vertical-align: middle;
        height: 100%;
        font-size: 14px;
        background: transparent;
      }

      &-icon {
        display: inline-block;
        vertical-align: middle;
      }

      &-division {
        border-left: 1px solid $concrete-gray;
        margin-left: 4px;
        padding-left: 8px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

    }

    &__header {
      font-weight: $semiBold;
      display: block;
      border-bottom: 1px solid $concrete-gray;
      background: $morning-fog-gray;
    }

    &__body {
      display: block;
      transition: .4s ease-out;

      &--editable {
        .wethod-table__row {
          height: 64px;
          line-height: 64px;
        }
      }
    }

    &__content {
      border: 1px solid $concrete-gray;
      width: 100%;
    }

    &__row {
      position: relative;

      &:nth-child(even) {
        background: $spring-wood-white;
      }

      &:nth-child(odd):not(.wethod-table__row--header) {
        background: $white;
      }

      height: 52px;
      line-height: 52px;
      vertical-align: middle;
      display: block;
      overflow: hidden;

      &:not(:last-child) {
        border-bottom: 1px solid #ECECEC;
      }

      &--header {
        background: transparent;
        height: 56px;
        line-height: 56px;
      }

      &--summary {
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        border-top: 1px solid $concrete-gray;
        border-bottom: none;
      }
    }

    &__cell {
      padding: 0 8px;
      text-align: left;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: top;

      .wethod-input-validator__helper-text {
        top: -15px
      }

      .wethod-input-decorator__helper-text {
        padding: 0 !important;
      }

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &-icon-sort {
        display: inline-block;
        vertical-align: middle;
        margin-left: -12px;
        transition: .2s;
      }

      &--sortable {
        &:hover {
          background: $concrete-gray;
        }

        cursor: pointer;
        transition: .2s;
      }

      &--feedback {
        position: absolute;
        color: $bright-sky-blue;
        left: 0;
        font-size: 10px;
        line-height: 15px;
        bottom: 0px;
        background: inherit;
        padding-left: 17px;
      }

      &--empty {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        white-space: pre-wrap;
        width: 100%;
        height: 100%;
        line-height: 1.5;
        padding: 0 15px;
        font-size: 12px;
        background: $white;
        color: $steel-gray;
        font-weight: $semiBold;
        text-transform: uppercase;
      }

      &--loading {
        text-align: center;
        width: 100%;

        .wethod-spinner--small {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .wethod-input-decorator {
        line-height: initial;
        vertical-align: middle;
        position: relative;

        &__helper-text {
          position: absolute;
        }
      }

      .wethod-input--basic {
        &.wethod-input--readonly {
          .wethod-input-decorator__child-wrapper {
            padding: 0;
          }
        }
      }

      &--selector {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &__footer {
      height: 36px;
      line-height: 36px;
      font-size: 12px;
      border-bottom: none;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $concrete-gray;
      border-left: 1px solid $concrete-gray;
      border-right: 1px solid $concrete-gray;

      &-button {
        background: transparent;
        border: none;
        outline: none;
        display: inline-block;
        padding: 4px 8px;
        border-radius: 0;
        transition: .3s;
        cursor: pointer;
        position: relative;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50px;
          opacity: 1;
        }

        &-label {
          display: inline-block;
          margin-right: 16px;
          vertical-align: middle;
        }

        &-icon {
          position: absolute;
          right: 3px;
          top: 2px;
        }
      }
    }

    &__input {
      &-radial {
        .wethod-menu__list {
          max-height: 200px;
          overflow: auto;
        }

        &-button {
          display: inline-block;
          background: #F2F3F5;
          height: initial;
          line-height: initial;
          padding: 4px;
          border-radius: 100%;
          cursor: pointer;
        }
      }

      &-select2 {
        pointer-events: none;
        color: $dusty-gray;
      }
    }
  }

  &-calendar-table {
    &__navigator {
      width: 16%;

      &-button {
        &--now.wethod-button {
          color: $black;
          font-size: 11px;
          padding: 0 18px;
          border-color: $black;

          &:hover {
            color: white !important;
            background: rgba(0, 0, 0, 0.8);
          }
        }

        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;

        &--first {
          margin-left: 10px;
          margin-right: 4px;
        }

        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.1);
          height: 100%;
          display: block;
          width: 100%;
          border-radius: 50%;
          transition: .3s;
          opacity: 0;
        }

        &:hover:before {
          opacity: 1;
        }
      }
    }

    &__now {
      border: 1px solid;
      border-radius: 4px;
      padding: 2px 8px;
    }
  }

  &-image-input {
    &__preview {
      overflow: hidden;
      margin: auto;
    }

    img {
      width: 100%;
      height: auto;
    }

    input[type=file] {
      display: none;
    }

    button {
      display: block;
      margin: 60px auto;
    }
  }

  &-slider {
    position: relative;

    &--disabled {
      .wethod-slider__input {
        cursor: auto;

        &::-webkit-slider-thumb {
          display: none;
          visibility: hidden;
        }

        &::-moz-range-thumb {
          display: none;
          visibility: hidden;
        }
      }
    }

    &__input {
      appearance: none;
      outline: none;
      background: transparent;
      height: 5px;
      border-radius: 50px;
      width: 100%;
      position: absolute;
      top: 0;
      cursor: pointer;

      &::-webkit-slider-thumb {
        appearance: none;
        cursor: pointer;
        height: 12px;
        width: 12px;
        background-color: $white;
        box-shadow: 0 0 2px 0 $dusty-gray;
        border-radius: 50%;
        border: none;
      }

      /* Note that while we're repeating code here, that's necessary as you can't comma-separate these type of selectors.
      Browsers will drop the entire selector if it doesn't understand a part of it. */
      &::-moz-range-thumb {
        appearance: none;
        cursor: pointer;
        height: 12px;
        width: 12px;
        background-color: $white;
        box-shadow: 0 0 2px 0 $dusty-gray;
        border-radius: 50%;
        border: none;
      }

      &::-moz-range-track {
        appearance: none;
        outline: none;
        background: transparent;
        height: 5px;
        border-radius: 50px;
        width: 100%;
        cursor: pointer;
      }

      &::-moz-focus-outer {
        border: none;
      }
    }

    &__track {
      background: $concrete-gray;
      height: 5px;
      border-radius: 50px;
      width: 100%;
      position: relative;
      cursor: pointer;
    }

    &__progress {
      background: $bright-sky-blue;
      height: 5px;
      position: absolute;
      border-radius: 50px;
      top: 0;
      cursor: pointer;
    }
  }

  &-profile-pic {
    width: 100%;
    height: 100%;
    background: $black;
    position: relative;
    border-radius: 50%;
    margin-right: 32px;

    &__image {
      padding: 1px;
      position: relative;
      width: 100%;
      height: 100%;

      &-container {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &__placeholder {
      text-align: center;
      background: #2b2e38;
      border-radius: 50%;
      color: $white;
      font-size: inherit;
      line-height: inherit;
      height: 100%;
    }
  }

  &-clearfix {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }

  &-spinner {
    width: 100px;
    height: 100px;
    position: relative;
    background-image: url(../img/icon/logo_wait.png);
    display: inline-block;
    -webkit-animation: logoRotation 1.3s ease;
    animation: logoRotation 1.3s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    &--small {
      width: 30px;
      height: 30px;
      background-image: url(../img/icon/mini_logo_wait.png);
    }
  }

  &-section {
    background: $alabaster-white;
    border-radius: 16px 16px 0 0;
    border-top: 1px solid $concrete-gray;
    min-height: calc(100vh - 62px);
    @include scaleToExtraSmall() {
      border-radius: 0;
      border-top: 0;
    }

    &--economics {
      border-radius: 0;
      border-top: 0;
    }

    &-body {
      max-width: 1024px;
      margin: auto;
    }

    &-actions {
      &-wrapper {
        position: fixed;
        width: 100%;
        top: 0;
        max-width: 1024px;
        background: $maldivian-sand-white;
        z-index: 100;
      }
    }

    &-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 101;
      background: rgba(204, 204, 204, 0.35);
      left: 0;
      top: 122px;

      @include scaleToExtraSmall() {
        top: 108px;
      }
    }
  }

  &-list {
    &-header {
      background-color: $morning-fog-gray;
      color: $black;
      line-height: 30px;
      height: 30px;
      width: 100%;
      font-weight: $semiBold;
      font-size: 12px;
      z-index: 2;
      float: left;
    }

    &-column {
      height: 100%;
      padding: 0 6px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-input {
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    width: 100%;
    background-color: #F2F3F5;
    line-height: 17px;

    input, textarea {
      margin: 0 5px !important;
      border: none !important;
      background-color: inherit !important;
      outline: none;
      padding: 0 !important;
      width: calc(100% - 10px);
    }

    &:hover, &:focus {
      background-color: $concrete-gray;
    }

    &--required {
      &:not(.wethod-input-decorator) {
        &:before {
          content: '*';
          display: inline-block;
          color: $bright-sky-blue;
        }

        input {
          margin: 0 5px;
          border: none;
          background-color: inherit;
          outline: none;
          width: calc(100% - 17px);
        }
      }
    }

    &-validator {
      &--error {
        .wethod-input {
          border: 1px solid $raspberry-red;

          &--required:before {
            color: $raspberry-red;
          }
        }
      }

      &__helper-text {
        font-size: 10px;
        line-height: 12px;
        color: $raspberry-red;
        padding: 0 7px;
        position: relative;
        display: block;
      }
    }
  }

  &-day-picker {
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    line-height: 16px;
    border-radius: inherit;

    .DayPicker-Caption {
      color: $bright-sky-blue;
    }

    .DayPicker-Body {
      color: #4A4A4A;
    }

    .DayPicker-Day {
      outline: none;

      &--today {
        color: $bright-sky-blue;
      }

      &--holiday {
        position: relative;

        &::after {
          content: '';
          background: $sunset-orange;
          width: 4px;
          height: 4px;
          display: block;
          border-radius: 50%;
          position: absolute;
          left: calc(50% - 2px);
        }
      }
    }

    .DayPicker-NavButton--prev {
      @extend .wethod-icon-back;
      margin: 0;
      top: 4px;
      right: 48px;
      outline: none;
      border-radius: 50%;

      &:hover {
        background-color: rgba(72, 165, 249, 0.15);
      }
    }

    .DayPicker-NavButton--next {
      @extend .wethod-icon-forward;
      margin: 0;
      top: 4px;
      right: 10px;
      outline: none;
      border-radius: 50%;

      &:hover {
        background-color: rgba(72, 165, 249, 0.15);
      }
    }
  }

  &-date-picker {
    &-button {
      &--deletable {
        justify-content: space-between;

        .wethod-icon-button {
          display: none;
        }

        &:hover {
          .wethod-icon-button {
            display: block;
          }
        }
      }
    }

    &-menu {
      .wethod-menu__list {
        min-width: auto !important;
      }
    }

    &__reset {
      display: none !important;
    }

    &:hover {
      .wethod-date-picker__reset {
        display: block !important;
      }
    }
  }

  &-date-range-picker {
    line-height: 16px;

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      color: initial;
      background-color: rgba(72, 165, 249, 0.15) !important;
    }

    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      border-radius: 50% !important;
      background-color: #ECECEC !important;
    }

    .DayPicker-Day {
      border-radius: 0 !important;
    }

    .DayPicker-Day--today {
      color: $bright-sky-blue;
    }

    .DayPicker-Day--start:not(.DayPicker-Day--outside) {
      color: white !important;
      background-color: $bright-sky-blue;
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }

    .DayPicker-Day--end:not(.DayPicker-Day--outside) {
      color: white !important;
      background-color: $bright-sky-blue;
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }

  &-month-picker {
    width: 200px;
    height: 210px;
    line-height: initial;

    button {
      outline: none;
      border: none;
      cursor: pointer;
    }

    &__header {
      font-size: 14px;
      color: $bright-sky-blue;
      padding: 5px 10px 5px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: transparent;
        border-radius: 50%;

        &:hover {
          background-color: rgba(72, 165, 249, 0.15);
        }
      }
    }

    &__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      > * {
        flex: 1 1 33%;
        text-align: center;
        padding: 8px;
      }
    }

    &__month {
      font-size: inherit;
      border-radius: 12px;
      background-color: transparent;
      height: 25px;
      width: 42px;

      &:hover {
        background-color: rgba(72, 165, 249, 0.15);
      }

      &--selected {
        color: $white;
        background-color: $bright-sky-blue;

        &:hover {
          background-color: $bright-sky-blue;
        }
      }

      &--disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
        cursor: default;

        &:hover {
          background-color: $bright-sky-blue;
        }
      }
    }
  }

  &-month-select {
    &__current {
      cursor: pointer;
      padding: 0 18px 0 8px;
      border-radius: 30px;
      transition: .4s;
      position: relative;

      &:hover {
        background: #F2F3F5;
      }

      span {
        display: inline-block;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }

    &__list {
      position: absolute;
      width: 100%;
      max-height: 240px;
      background: $white;
      color: $black;
      height: 330px;
      box-shadow: 0 0 7px 0 rgba(155, 155, 155, 0.2);
      float: left;
      overflow: auto;
      z-index: 2;
      font-size: 12px;
    }

    &__years {
      float: left;
      overflow-y: scroll;
      height: 100%;

      .push {
        position: absolute;
        top: 0;
      }

      .stretch {
        padding-top: 30px;
      }
    }

    &__year {
      border-bottom: 1px solid $concrete-gray;
      float: left;
      width: 100%;

      &-label {
        padding: 8px;
        background: $white;
        width: 100%;
        position: relative;
        height: 30px;
        color: $bright-sky-blue;
      }
    }

    &__month {
      padding: 8px;
      height: 30px;
      cursor: pointer;

      &:hover {
        color: $bright-sky-blue;
      }
    }
  }

  &-button {
    line-height: 28px;
    color: $black;
    background-color: transparent;
    padding: 0 20px;
    border-radius: 100px;
    border: 1px solid;
    outline: none;
    transition: 0.15s ease-in-out;
    cursor: pointer;
    max-width: 100%;

    &.disabled {
      pointer-events: none;
    }

    &:hover {
      color: $white !important;
      background-color: $black;
    }

    &--warning {
      color: $raspberry-red;

      &:hover {
        background-color: $raspberry-red;
      }
    }

    &--confirm {
      color: $garden-green;

      &:hover {
        background-color: $garden-green;
      }
    }

    &--selected {
      color: $white;
      background-color: $black;
    }

    &--alert {
      color: $sunset-orange;

      &:hover {
        background-color: $sunset-orange;
      }
    }

    &--no-border {
      border: none;
      padding: 0 7px;

      &:hover {
        margin: 0;
        color: $white !important;
      }
    }

    &--colored {
      border: none;
      width: 120px;
      color: initial;
      margin: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        opacity: 0.9;
        color: initial;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-icon-button {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px;
    transition: 0.2s ease-in-out;
    border: 1px solid $black;
    border-radius: 50%;
    height: 30px;
    cursor: pointer;
    position: relative;
    outline: none;
    background: transparent;

    &:hover {
      color: $white;
      background-color: $black;
    }

    &--common {
      // size of the button depends on the content (icon) size
      width: unset;
      height: unset;
      max-width: 32px;
      max-height: 32px;

      .wethod-icon { // Center the icon
        margin: -1px 0 0 -1px !important;
      }
    }

    &--black {
      border: 1px solid $black;
      background: transparent;

      &:hover {
        background-color: $black;

        .wethod-icon {
          background-position-x: -64px; // white icon
        }

        .wethod-icon-medium {
          background-position-x: -48px; // white icon
        }

        .wethod-icon-small {
          background-position-x: -32px; // white icon
        }
      }
    }

    &--medium {
      width: 26px;
      height: 26px;

      &:hover {
        .wethod-icon {
          background-position-x: -48px; // white
        }
      }

      &-white {
        &:hover {
          background-color: $white;

          .wethod-icon {
            background-position-x: -24px; // black
          }
        }
      }
    }

    &--small {
      width: 18px;
      height: 18px;

      &:hover {
        .wethod-icon {
          background-position-x: -32px; // white
        }
      }

      &-white {
        &:hover {
          background-color: $white;

          .wethod-icon {
            background-position-x: -16px; // black
          }
        }
      }
    }

    &--no-border {
      border: none;
    }

    &--no-margin {
      margin: 0;
    }

    &--tooltip {
      width: 33px;
      height: 33px;

      .wethod-icon {
        display: block;
      }

      &:hover {
        .wethod-icon {
          background-position-x: -64px;
        }

        .wethod-icon-medium {
          background-position-x: -48px;
        }
      }
    }

    .wethod-icon, .wethod-icon-medium, .wethod-icon-small {
      display: block;
      margin: auto;
    }
  }

  &-tab-switcher {
    height: 32px;
    cursor: pointer;
    color: $black;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid $black;
    outline: none;
    transition: 0.15s ease-in-out;
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    &__item {
      height: 100%;
      cursor: pointer;
      color: $black;
      background-color: transparent;
      padding: 0 17px;
      border: none;
      outline: none;
      transition: 0.15s ease-in-out;
      display: inline-flex;
      align-items: center;

      &:not(:last-child) {
        border-right: 1px solid $black;
      }

      &:hover, &--selected {
        color: $white;
        background-color: $black;
      }

      &:disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
      }
    }

    &--black {
      border-color: $black;

      .wethod-tab-switcher {
        &__item {
          color: $black;

          &:hover, &--selected {
            background: $black;
            color: $white;
          }

          &:not(:last-child) {
            border-color: $black;
          }
        }
      }
    }
  }

  &-search-select {
    position: relative;

    &__dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      right: 12px;
      top: calc(50% - 8px);
    }

    &__list {
      position: absolute;
      border-radius: 3px;
      background-color: #F2F3F5;
      z-index: 2;
      box-shadow: 0 1px 6px 0 #E4EAEF;
      min-width: 100%;
      white-space: normal;
      max-height: 300px;
      overflow-y: scroll;
      padding: 0 !important;
    }

    &__item {
      line-height: 18px;
      padding: 5px !important;
      cursor: pointer;
      margin: 0 !important;
      position: relative;

      &:hover {
        background-color: $concrete-gray;
      }

      &--empty {
        text-transform: uppercase;
        font-weight: $semiBold;
      }

      &--loading {
        text-align: center;
      }
    }

    &__add-button {
      font-size: 18px;
      line-height: 20px;
      color: $white;
      background-color: $bright-sky-blue;
      padding: 0 5px;

      position: relative;
      float: right;
      top: -25px;
      margin-right: 5px;
    }
  }

  &-item-creator {
    cursor: auto;

    &:hover {
      background: inherit;
    }

    &__field {
      margin-top: 4px;

      label {
        font-weight: $light;
        text-transform: capitalize;
        display: block;
        line-height: initial;
      }

      span {
        padding: 5px;
        display: inline-block;
        width: 100%;
        line-height: 17px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 0;

        &:before {
          content: '*';
          display: inline-block;
          color: $bright-sky-blue;
        }

        input {
          margin: 0 5px;
          border: none;
          background-color: inherit;
          outline: none;
          width: calc(100% - 17px);
        }
      }
    }

    &__value {
      font-weight: $bold;
    }

    &__actions {
      text-align: center;
      margin-top: 20px;
    }

    &__feedback {
      font-size: 11px;
      line-height: 11px;
      color: $bright-sky-blue;
      margin-top: 5px;
      text-align: center;

      &--error {
        color: $raspberry-red;
      }
    }
  }

  &-tags {
    display: inline-block;

    &__add-item {
      display: inline-block;
      position: relative;
    }

    input {
      margin: 4px 5px;
      border: none;
      outline: none;
    }

    &__select {
      position: absolute;
      background: $white;
      z-index: 2;
      border-radius: 3px;
      box-shadow: 0 1px 6px 0 #E4EAEF;
      max-height: 300px;
      overflow-y: scroll;
      min-width: 100%;

      &-item {
        padding: 5px;
        line-height: 18px;
        cursor: pointer;
        list-style: none;

        &:hover {
          background-color: $concrete-gray;
        }

        &-uses {
          color: rgba(0, 0, 0, 0.5);
          font-size: 11px;
          line-height: 10px;
        }
      }
    }
  }

  &-card {
    background: $white;
    border-radius: 3px;
    padding: 16px;
    position: relative;

    &__header {
      text-align: right;
    }
  }

  &-collapsible-card {
    &__arrow {
      display: inline-block;
      position: relative;
      top: 9px;
    }

    &__title {
      color: $bright-sky-blue;
    }

    &__header {
      background: $white;
      border-radius: 3px;
      padding: 0 16px;
      margin-bottom: 1px;
      line-height: 48px;
      cursor: pointer;

      &:hover {
        background: rgba(220, 220, 220, 0.5);
      }

      &-left, &-right {
        display: inline-block;
        width: 50%;
      }

      &-right {
        text-align: right;
      }
    }

    &__content {
      background: $white;
      border-radius: 3px;
      padding: 8px 16px;
    }
  }

  &-filter {
    &-select {

      &__active-filters {
        display: inline-block;
        line-height: 24px;
        font-size: 10px;
        margin-left: 4px;

        &-item {
          background: rgba(79, 196, 247, 0.5);
          border-radius: 50px;
          display: inline-block;
          color: $white;
          padding: 0 10px;
          margin: 2px;
        }
      }

      &__list {
        display: none;
        position: absolute;
        margin-top: 4px;
        border-radius: 2px;
        background-color: $white;
        padding-top: 8px;
        z-index: 3;
        box-shadow: 0 2px 10px 0 rgba(155, 155, 155, 0.2);
        transition: 0.2s ease-in-out;

        &--visible {
          opacity: 1;
        }
      }

      &__title {
        color: $bright-sky-blue;
        line-height: 20px;
        padding: 0 12px;
      }

      &__item {
        font-size: 12px;
        line-height: 17px;

        a {
          padding: 8px 12px;
          color: $black;
          width: 100%;
          display: inline-block;

          &:hover {
            background: rgba(220, 220, 220, 0.5);
          }
        }
      }

      &__button {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include scaleToExtraSmall() {
          padding: 0 12px;
        }
      }
    }
  }

  &-select2 {
    /* Hide description when item is selected, this way the select button only displays item's name in order to taking up
    the less space possible */
    .wethod-button .wethod-select__item-description {
      display: none;
    }

    &__list {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      margin-top: 4px;
      border-radius: 2px;
      background-color: $white;
      z-index: 2;
      box-shadow: 0 2px 10px 0 rgba(155, 155, 155, 0.2);
      transition: 0.2s ease-in-out;

      &--visible {
        visibility: visible;
        opacity: 1;
      }
    }

    &__item {
      font-size: 12px;
      line-height: 17px;

      a {
        padding: 8px 12px;
        color: $black;
        width: 100%;
        display: inline-block;

        &:hover {
          background: rgba(220, 220, 220, 0.5);
        }
      }

      &-description {
        font-weight: 100;
        display: inline-block;
        margin-left: 8px;
        color: #999;
        font-size: 11px;
        vertical-align: middle;
        position: relative;
        top: -2px;
      }
    }
  }

  &-mdl-select-input {
    padding: 0;

    button {
      border: none;
      padding-top: 6px !important;
      font-size: 12px !important;
      width: 100%;
      outline: none;
      text-align: left;
      background: inherit;
      cursor: pointer;
    }
  }

  &-modal {
    &__spaced-paragraph {
      margin: 10px 0;
    }

    &-tab {
      margin: 0 8px;
      color: grey !important;

      &-container {
        text-align: center;
        margin: 10px 0;
      }

      &--selected {
        border-bottom: 2px solid $bright-sky-blue;
      }
    }

    &-list {
      border-radius: 3px;
      display: inline-block;
      height: 86px;
      overflow-y: auto;
      width: 100%;
      line-height: 17px;
      text-align: center;
      border: 1px solid #F2F3F5;

      li {
        padding: 2px 5px;

        a {
          color: $black;
          width: 100%;
          display: inline-block;
        }

        &:hover {
          background: #F2F3F5;
        }
      }
    }
  }

  &-modal2 {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    text-align: center;
    overflow: auto;
    height: 100vh;
    padding-bottom: 50px;

    &--disabled:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &-input--error {
      .wethod-input {
        border-color: $raspberry-red;
      }
    }

    .wethod-icon-button {
      margin-left: 10px;
      border: none;
      padding: 3px;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .wethod-search-select__item {
      padding: 8px 16px !important;
    }

    &__container {
      margin: auto;
      margin-top: 200px;
      max-width: 720px;
      background: $white;
      border: 1px solid $concrete-gray;
      border-radius: 12px;
      box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
      text-align: left;
      display: inline-block;
    }

    &__feedback {
      color: $bright-sky-blue;
      font-size: 12px;
      position: relative;
      bottom: -8px;

      &--error {
        color: $raspberry-red;
      }

      &--warning {
        color: $sunset-orange;
      }
    }

    &__header {
      padding: 12px 24px;
      border-bottom: 1px solid $concrete-gray;

      h2 {
        font-size: 18px;
        line-height: 24px;
        float: left;
      }

      &-action {
        cursor: pointer;
        float: right;
        margin-left: 40px;

        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.1);
          height: 100%;
          display: block;
          width: 100%;
          border-radius: 50%;
          transition: .3s;
          opacity: 0;
        }

        &:hover:before {
          opacity: 1;
        }
      }

      &:after {
        @extend .wethod-clearfix;
      }
    }

    &__body {
      padding: 24px;
      line-height: 18px;
      font-size: 14px;

      p, ul {
        margin-bottom: 16px;
      }

      ul {
        margin-bottom: 16px;
        list-style: none;
        padding: 0 24px;
      }

      li {
        margin: 4px 0;
      }

      b {
        font-weight: $semiBold;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;

      .wethod-button {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    &-list {
      &__header {
        &-item {
          padding: 0 16px;
          display: inline-block;
          font-weight: normal !important;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .material {
      &-input {
        &__input {
          margin: 0;

          .wethod-input {
            background: $white;

            &:hover {
              background: initial;
            }

            textarea {
              margin: 0 !important;
              width: 100%;
            }
          }
        }

        &__label {
          background: $white;
        }
      }
    }

    &--medium {
      .wethod-modal2__container {
        width: 450px;
      }
    }
  }

  &-info-tooltip {
    border: 1px solid $black;
    border-radius: 50%;
    width: 18px;
    height: 18px;

    .tooltipReact {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      background-color: $concrete-gray;

      .tooltipReact {
        opacity: 1;
      }
    }

    &__icon {
      display: block;
    }
  }

  &-rating {
    &--small {
      .wethod-rating {
        &__star {
          width: 16px;
          height: 16px;
        }

        &__value {
          font-size: 12px;
        }
      }
    }

    &__star {
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: 0.1s;
      display: inline-block;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        transform: scale(1.2);
      }

      &.disabled, &--read-only {
        &:hover {
          transform: none;
        }

        cursor: initial;
      }

      &-list {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__label {
      display: none;
    }

    &__value {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin-left: 12px;
    }

    input {
      display: none;
    }
  }

  &-text-preview {
    &__button {
      &-more {
        border: 0;
        background: transparent;
        font-size: 14px;
        color: $bright-sky-blue;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }

  &-info-header {
    padding: 12px 16px;
    background: $white;
    border: 1px solid $concrete-gray;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &__description {
      flex-grow: 2;

      &-content {
        display: flex;
        align-items: center;
      }
    }

    &__pic {
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin-right: 25px;
      font-size: 20px;
    }

    &__title {
      font-weight: $semiBold;
      font-size: 18px;
      line-height: 28px;
    }

    &__subtitle {
      font-size: 16px;
      color: $dusty-gray;
    }

    &__sidebar-button {
      margin-left: 24px;

      &:hover {
        background-color: $concrete-gray;
      }
    }
  }

  &-vertical-line {
    height: 35px;
    border-left: 1px solid $concrete-gray;
    vertical-align: middle;
    margin: 0 5px;
    display: inline-block;
  }
}

.wethod-tag-blink {
  animation-name: wethod-tag-blink;
  animation-duration: .5s;
  animation-fill-mode: both;
}

@keyframes wethod-tag-blink {
  50% {
    background-color: $bright-sky-blue;
    color: $white;
  }
}
